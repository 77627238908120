import React, { useState } from "react";
import { SubmitAnswerApi } from "../../../api/request/service.js";
import toast from "react-hot-toast";

const MyRadio = ({ data, reloadSections, reloadQuestions }) => {
  const question = data?.service_question;
  const [selectedValue, setSelectedValue] = useState(data?.response_text);
  const isReviewedChecked = data?.is_sealed;
  const disabledStyle = { cursor: "not-allowed", opacity: 0.6 };

  const onChange = async (value) => {
    if (!value) return;
    try {
      setSelectedValue(value);

      const answer = { id: data?.id, response_text: value };
      await SubmitAnswerApi(answer);

      // Update section and question data so conditional section and question can render
      reloadSections(false);
      reloadQuestions(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      {data?.is_show === 1 && (
        <div className="mt-6">
          <div className="flex items-start gap-2 mb-2">
            {question?.is_required === 1 && (
              <p className="text-red-600 text-lg">*</p>
            )}
            <p>{question?.field_label}</p>
          </div>

          <div className="w-full flex flex-wrap gap-5">
            {question?.options?.map((item, index) => (
              <div
                key={index}
                disabled={isReviewedChecked}
                style={isReviewedChecked ? disabledStyle : {}}
                className={`py-3 px-12 font-medium border-2 rounded-md cursor-pointer duration-500 ${
                  selectedValue === item
                    ? "text-primary border-primary bg-primaryLight"
                    : "text-secondaryLight1"
                }`}
                onClick={!isReviewedChecked ? () => onChange(item) : null}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyRadio;
