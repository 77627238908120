import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { SubmitAnswerApi } from "../../../api/request/service";
import toast from "react-hot-toast";

const MyDatePicker = ({ data }) => {
  const question = data?.service_question;
  const isReviewedChecked = data?.is_sealed;
  const disabledStyle = { cursor: "not-allowed" };

  const onChange = async (date, dateString) => {
    if (!dateString) return;
    try {
      const answer = {
        id: data?.id,
        response_text: dateString || "",
      };
      await SubmitAnswerApi(answer);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      {data?.is_show === 1 && (
        <div className="mt-6">
          <div className="flex items-start gap-2 mb-2">
            {question?.is_required === 1 && (
              <p className="text-red-600 text-lg">*</p>
            )}
            <p>{question?.field_label}</p>
          </div>

          <DatePicker
            size="large"
            disabled={isReviewedChecked}
            style={isReviewedChecked ? disabledStyle : {}}
            className="w-full py-3 ps-4"
            format="YYYY-MM-DD"
            placeholder="YYYY-MM-DD"
            defaultValue={
              data?.response_text ? dayjs(data?.response_text) : null
            }
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default MyDatePicker;
