import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.scss";
import RadialBarChart from "../../components/chart/RadialBarChar";
import columns from "../../columns/dashboard/columns";
import SkeletonTable from "../../components/table/SkeletonTable";
import { GetClientDashboard, SubmitSignatureApi } from "../../api/request/dashboard/dashboard";
import toast from "react-hot-toast";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Stripe from "../../services/stripe/Stripe";
import moment from "moment";
import { Button, Form, Image, Upload } from "antd";
import { saveAs } from "file-saver";
import ModalComponent from "../../components/modals/ModalComponent";
import LoadableButton from "../../components/button/LoadableButton";
import Slider from "react-slick";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const [services, setServices] = useState([]);
  const [billingData, setBillingData] = useState([]);
  const [dashboardCards, setDashboardCards] = useState([
    {
      title: "Tickets",
      total: "00",
      icon: "/assets/icons/ticket.svg",
      bgColor: "bg-[#E5FAFF]",
    },
    {
      title: "Active Files",
      total: "00",
      icon: "/assets/icons/active-file.svg",
      bgColor: "bg-[#DDF6E8]",
    },
    {
      title: "Apply Signature",
      total: "00",
      icon: "/assets/icons/apply-signature.svg",
      bgColor: "bg-[#cdd8ec]",
    },
  ]);
  const sliderRef = useRef(null);

  const [uploadFileForm] = Form.useForm();
  const [documentId, setDocumentId] = useState("");
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  const contactInfo = [
    { icon: "/assets/icons/phone.svg", label: "Phone", key: "phone" },
    { icon: "/assets/icons/email.svg", label: "Email", key: "email" },
    { icon: "/assets/icons/location.svg", label: "Address", key: "address" },
    { icon: "/assets/icons/web.svg", label: "Website", key: "website_url" },
  ];

  const socialLinks = [
    {
      name: "Facebook",
      icon: "/assets/icons/facebook.svg",
      url: dashboardData?.facebook_url,
      bgColor: "bg-[#3B599933]",
    },
    {
      name: "Instagram",
      icon: "/assets/icons/instagram.svg",
      url: dashboardData?.instagram_url,
      bgColor: "bg-gradient-to-b from-[rgba(219,0,172,0.2)] to-[rgba(255,203,0,0.2)]",
    },
    {
      name: "LinkedIn",
      icon: "/assets/icons/linkedIn.svg",
      url: dashboardData?.linkedin_url,
      bgColor: "bg-[#0B69C733]",
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // For smaller screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue("--primary")?.trim();

  const getDashboardDetails = async () => {
    try {
      const { data } = await GetClientDashboard();
      if (data?.status) {
        setDashboardData(data?.data);
        const updatedTotals = [data?.data?.total_tickets, data?.data?.active_files, data?.data?.apply_for_sign];
        setDashboardCards(
          dashboardCards?.map((card, index) => ({
            ...card,
            total: updatedTotals[index] || "00",
          }))
        );
        setServices(data?.data?.services);
        setBillingData(
          data?.data?.billing_details?.map((item) => {
            return {
              ...item,
              action: (
                <Elements stripe={stripePromise}>
                  <Stripe orgData={item} />
                </Elements>
              ),
            };
          })
        );
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getDashboardDetails();
  }, []);

  const downloadPDF = (url) => {
    if (!url) return;
    saveAs(url, url.split("/").pop());
  };

  const submitSignature = async (values) => {
    if (!documentId) return;
    try {
      setIsUploadFileLoading(true);
      const params = {
        document_id: documentId,
        file: values?.files[0]?.originFileObj,
      };
      const apiHeader = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await SubmitSignatureApi(params, apiHeader);
      if (data?.status) {
        toast.success(data?.message);
        uploadFileForm.resetFields();
        setDocumentId("");
        setIsOpenUploadModal(false);
        getDashboardDetails();
      }
      setIsUploadFileLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) toast.error(error?.response?.data?.message);
      setIsUploadFileLoading(false);
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    uploadFileForm.setFieldsValue({ files: newFileList });
    if (newFileList?.length == 0) {
      uploadFileForm.setFieldsValue({ files: [] });
    }
  };

  return (
    <div className="grid sm:grid-cols-4 gap-4 sm:px-0 px-1">
      <div className="sm:col-span-3">
        <div className="dashboard-banner bg-primary rounded-xl sm:h-56 flex justify-between items-center px-5 py-5">
          <div className="bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center">
            <img src="/assets/icons/left-angle.svg" alt="icon" className="object-contain " />
          </div>
          <h4 className="text-White font-semibold text-2xl text-center">
            Securely upload documents on our user-friendly trackmydoc platform
          </h4>
          <div className="bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center">
            <img src="/assets/icons/right-angle.svg" alt="icon" className="object-contain " />
          </div>
        </div>

        <div className="grid sm:grid-cols-3 gap-5 mt-5 ">
          {dashboardCards?.map((item, index) => (
            <div key={index} className="border rounded-xl p-4 bg-white">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-medium">{item.title}</p>
                  <p className="font-semibold text-2xl mt-2">{item.total}</p>
                </div>
                <div className={`${item.bgColor} rounded-xl size-14 flex items-center justify-center`}>
                  <img src={item.icon} alt="icon" />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="border rounded-xl p-5 mt-5 bg-White">
          <div className="flex justify-between">
            <p className="font-semibold">File Status</p>
            <div className="flex gap-2">
              <div className="bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center">
                <img src="/assets/icons/left-angle.svg" alt="icon" className="object-contain " />
              </div>
              <div className="bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center">
                <img src="/assets/icons/right-angle.svg" alt="icon" className="object-contain " />
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Slider {...sliderSettings}>
              {services.map((service, index) => (
                <div key={index} className="px-2">
                  <div className="border rounded-xl">
                    <div className="px-3">
                      <h4 className="mt-3">{service?.service_name}</h4>
                      <hr className="mt-1" />
                    </div>
                    <div className="flex sm:flex-row flex-col items-center justify-between px-5">
                      <div className="space-y-4 py-2">
                        <div className="text-Gray">
                          <h4 className="mb-2">{service?.date_created}</h4>
                        </div>
                        <div>
                          <h4 className="font-bold mb-1">Status</h4>
                          <p>
                            <span className="text-xl text-primary me-2">●</span> {service?.files_status}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <RadialBarChart
                          values={[service?.completion_percentage]}
                          labels={["Completed"]}
                          colors={[primaryColor]}
                          value={true}
                          height={200}
                          width={150}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div> */}
        <div className="border rounded-xl p-5 mt-5 bg-White">
          <div className="flex justify-between">
            <p className="font-semibold">File Status</p>
            <div className="flex gap-2">
              {/* Previous Button */}
              <div
                className="bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center cursor-pointer"
                onClick={() => sliderRef.current.slickPrev()} // Trigger the previous slide
              >
                <img src="/assets/icons/left-angle.svg" alt="icon" className="object-contain" />
              </div>
              {/* Next Button */}
              <div
                className="bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center cursor-pointer"
                onClick={() => sliderRef.current.slickNext()} // Trigger the next slide
              >
                <img src="/assets/icons/right-angle.svg" alt="icon" className="object-contain" />
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Slider ref={sliderRef} {...sliderSettings}>
              {services.map((service, index) => (
                <div key={index} className="px-2">
                  <div className="border rounded-xl">
                    <div className="px-3">
                      <h4 className="mt-3">{service?.service_name}</h4>
                      <hr className="mt-1" />
                    </div>
                    <div className="flex sm:flex-row flex-col items-center justify-between px-5">
                      <div className="space-y-4 py-2">
                        <div className="text-Gray">
                          <h4 className="mb-2">{service?.date_created}</h4>
                        </div>
                        <div>
                          <h4 className="font-bold mb-1">Status</h4>
                          <p>
                            <span className="text-xl text-primary me-2">●</span> {service?.files_status}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <RadialBarChart
                          values={[service?.completion_percentage]}
                          labels={["Completed"]}
                          colors={[primaryColor]}
                          value={true}
                          height={200}
                          width={150}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="border rounded-2xl mt-5 p-6 bg-White">
          <h3 className="text-xl font-bold mb-4">Waiting for action</h3>
          <div>
            {dashboardData?.waiting_for_action?.length ? (
              <>
                {dashboardData?.waiting_for_action?.map((item, index) => (
                  <div
                    key={index}
                    className={`border rounded-md flex justify-between items-center p-4 my-3 ${
                      item?.is_sign_done ? "border-green-600 cursor-not-allowed" : "border-Gray"
                    }`}
                  >
                    <div>
                      <p className={`text-lg capitalize ${item?.is_sign_done ? "text-green-600" : ""}`}>{item?.original_name}</p>
                      <div className="flex items-center gap-3">
                        <p className="text-sm text-Gray mt-1">{moment(item?.updated_at).format("lll")}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-3">
                      {item?.is_sign_done ? (
                        <p className="text-green-600 font-semibold text-base">Signed</p>
                      ) : (
                        <>
                          <Button type="primary" className="uppercase font-medium" onClick={() => downloadPDF(item?.path)}>
                            Download
                          </Button>
                          <Button
                            type="primary"
                            className="uppercase"
                            onClick={() => {
                              setDocumentId(item?.id);
                              setIsOpenUploadModal(true);
                            }}
                          >
                            Upload
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="text-secondaryLight1">No data</p>
            )}
          </div>
        </div>

        <div className="border rounded-2xl my-5 bg-White">
          <h1 className="my-5 mx-5 font-semibold text-xl">Billing Details</h1>
          <div className="my-6 billing-table">
            <SkeletonTable columns={columns} data={billingData} />
          </div>
        </div>
      </div>

      <div className="border rounded-2xl w-full space-y-5 bg-White">
        <div>
          <div className="bg-[#F1F1F1] py-2 ps-4">Contact Info</div>
          <div className="px-4 py-4">
            {contactInfo?.map((item, index) => (
              <div key={index} className="border-b flex items-center gap-3 py-2 px-">
                <div className="h-5 border-r-2 pe-2">
                  <img src={item?.icon} alt="icon" />
                </div>
                <div>
                  <p className="text-Gray">{item?.label}</p>
                  <p className="text-sm mt-1">{item?.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="bg-[#F1F1F1] py-2 ps-4">Social Links</div>
          <div className="flex gap-3 py-4 px-4">
            {socialLinks?.map((item, index) => (
              <a
                key={index}
                href={item.url || "#"}
                target="_blank"
                rel="noopener noreferrer"
                className={`${item.bgColor} h-8 w-8 rounded-full flex justify-center items-center`}
              >
                <img src={item.icon} alt={item.name} className="h-5 w-5" />
              </a>
            ))}
          </div>
          <div className="px-4 py-4">
            <div className="bg-[#F1F1F1] rounded-xl text-center content-center px-2 py-4">
              <h4 className="font-bold text-lg">Download mobile app</h4>
              <p className="text-sm mt-2">
                Download mobile app to upload and sign documents, send messages, pay bills and more-all in one place
              </p>
              <div className="space-x-3 pt-4">
                <a href={dashboardData?.playstore_url || "#"} target="_blank" rel="noopener noreferrer" className="h-10 inline-block">
                  <img src="/assets/icons/playstore-btn.svg" alt="Play Store" className="object-contain h-full" />
                </a>
                <a href={dashboardData?.applestore_url || "#"} target="_blank" rel="noopener noreferrer" className="h-10 inline-block">
                  <img src="/assets/icons/appstore-btn.svg" alt="App Store" className="object-contain h-full" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --- UPload file modal --- */}
      <ModalComponent isOpen={isOpenUploadModal} setIsOpen={setIsOpenUploadModal} title="Upload Signed File" width={600}>
        <Form className="w-full" autoComplete="off" form={uploadFileForm} initialValues={{ files: [] }} onFinish={submitSignature}>
          <div>
            <Form.Item name="files" rules={[{ required: true, message: "Please upload file." }]}>
              <Upload beforeUpload={() => false} listType="picture-card" fileList={fileList} onChange={handleChange}>
                {fileList.length >= 1 ? null : (
                  <button type="button" className="flex flex-col items-center gap-2">
                    <div className="size-7">
                      <img src="/assets/icons/upload.svg" alt="icon" />
                    </div>
                    Upload
                  </button>
                )}
              </Upload>
            </Form.Item>
            {previewImage && (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
          </div>
          <LoadableButton
            className="bg-secondary text-sm text-white font-medium px-8 py-2 mt-4 rounded-lg"
            type="submit"
            lable="Upload"
            isLoading={isUploadFileLoading}
            loadingLable="Uploading file..."
          />
        </Form>
      </ModalComponent>
    </div>
  );
};

export default Dashboard;
