import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Avatar, Button, Spin, Timeline } from "antd";
import QuestionContainer from "../../../components/questionContainer/QuestionContainer.jsx";
import LoadableButton from "../../../components/button/LoadableButton.jsx";
import {
  GetSectionListApi,
  GetSectionWiseQuestionApi,
  SubmitFileApi,
  RepeatSectionApi,
  RemoveSectionApi,
  SetActiveTaskApi,
} from "../../../api/request/service.js";

const QuestionAnswerFlow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [file, setFile] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isQuestionLoading, setIsQuestionLoading] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isCompleted, setIsCompleted] = useState(true);
  const [isNext, setIsNext] = useState(true);
  const [isAllowSectionRepeat, setIsAllowSectionRepeat] = useState(false);
  const [isPrimarySection, setIsPrimarySection] = useState(false);
  const [isLoadingRepeat, setIsLoadingRepeat] = useState(false);
  const [isLoadingRemoveSection, setIsLoadingRemoveSection] = useState(false);

  const getFileDetails = async (isLoad = true) => {
    try {
      if (isLoad) setIsLoading(true);
      const { data } = await GetSectionListApi({ file_id: id });
      if (data?.status) {
        setFile(data?.file_details);
        setSectionList(data?.data);
        if (data?.data?.length > 1) {
          setIsNext(true);
        } else {
          setIsNext(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFileDetails();
  }, [id]);

  const getSectionWiseQuestion = async (isLoad = true) => {
    if (!selectedSection) return;

    try {
      if (isLoad) setIsQuestionLoading(true);
      let params = {
        file_id: id,
        section_id: selectedSection?.section_id,
      };
      const { data } = await GetSectionWiseQuestionApi(params);
      if (data?.status) {
        setQuestions(data?.responses);
        setIsAllowSectionRepeat(data?.section?.allow_repeat);
        setIsPrimarySection(data?.section?.is_primary);
      }
      setIsQuestionLoading(false);
    } catch (error) {
      setIsQuestionLoading(false);
    }
  };

  useEffect(() => {
    getSectionWiseQuestion();
  }, [selectedSection]);

  const handleNextStep = () => {
    function getNextSection(sectionId) {
      // Find the index of the current section by section_id
      const index = sectionList.findIndex(
        (section) => section.section_id === sectionId
      );

      // Iterate over the remaining sections after the found index
      for (let i = index + 1; i < sectionList.length; i++) {
        if (sectionList[i].is_show === 1) {
          return sectionList[i];
        }
      }

      // Return null if no matching section is found
      return null;
    }
    let nextSection = getNextSection(selectedSection?.section_id);
    if (nextSection) {
      setSelectedSection(nextSection);
    }
    if (sectionList?.length === nextSection?.section_order) {
      setIsNext(false);
    } else {
      setIsNext(true);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoadingSubmit(true);
      let params = { file_id: id };
      const { data } = await SubmitFileApi(params);
      if (data?.status) {
        navigate("/files");
        await SetActiveTaskApi(params);
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      setSelectedSection(
        sectionList.find(
          (obj) =>
            obj.section_id === Object.keys(error?.response?.data?.data)[0]
        ) || null
      );
      toast.error("Please complete this section first.");
      setIsLoadingSubmit(false);
    }
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    if (sectionList?.length === section?.section_order) {
      setIsNext(false);
    } else {
      setIsNext(true);
    }
  };

  const handleRepeatSection = async () => {
    try {
      setIsLoadingRepeat(true);
      let params = {
        file_id: id,
        service_section_id: selectedSection?.section_id,
      };
      const { data } = await RepeatSectionApi(params);
      if (data?.status) getFileDetails(false);
      setIsLoadingRepeat(false);
    } catch (error) {
      setIsLoadingRepeat(false);
    }
  };

  const handleRemoveSection = async () => {
    try {
      setIsLoadingRemoveSection(true);
      let params = {
        service_section_id: selectedSection?.section_id,
      };
      const { data } = await RemoveSectionApi(params);
      if (data?.status) {
        getFileDetails(false);
        handleNextStep();
      }
      setIsLoadingRemoveSection(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsLoadingRemoveSection(false);
    }
  };

  return (
    <div className="grid xl:grid-cols-4 gap-4">
      <div className="border rounded-lg pt-3 bg-white">
        <p className="text-primary text-center font-semibold border-b pb-3 capitalize line-clamp-1">
          {file?.service_name_slug || "--"}
        </p>
        <div className="px-6 pt-8 h-[calc(100vh-160px)] overflow-y-auto">
          {isLoading ? (
            <div className="flex flex-col items-center gap-2 justify-center h-full">
              <Spin />
              <p className="text-secondaryLight1">Loading...</p>
            </div>
          ) : (
            <Timeline
              className="custom-timeline"
              items={sectionList
                ?.filter((item) => item?.is_show === 1)
                ?.map((item, index) => ({
                  dot: (
                    <Avatar
                      className={`cursor-pointer border-2 font-bold ${
                        item?.question_count === item?.filled_answers_count
                          ? "border-green-500 text-green-500"
                          : `${
                              selectedSection?.section_id === item?.section_id
                                ? "border-primary font-semibold bg-transparent text-primary duration-300"
                                : "border-secondaryLight1 text-secondaryLight1"
                            }`
                      }`}
                      style={{ backgroundColor: "var(--background-color)" }}
                    >
                      {index + 1}
                    </Avatar>
                  ),
                  children: (
                    <div
                      className="ms-1 mb-2 hover:bg-secondaryLight p-1 ps-2 rounded-md cursor-pointer"
                      onClick={() => handleSectionChange(item)}
                    >
                      <p className="text-secondaryLight1">
                        Question {item?.filled_answers_count}/
                        {item?.question_count}
                      </p>
                      <p className="font-bold">{item?.section_name}</p>
                    </div>
                  ),
                }))}
            />
          )}
        </div>
      </div>

      {selectedSection && (
        <div className="xl:col-span-3 border rounded-lg p-3 bg-white">
          {isQuestionLoading ? (
            <div className="flex flex-col items-center gap-2 mt-36">
              <Spin />
              <p className="text-secondaryLight1">Loading...</p>
            </div>
          ) : (
            <div>
              <QuestionContainer
                questions={questions}
                reloadSections={getFileDetails}
                reloadQuestions={getSectionWiseQuestion}
              />

              {isAllowSectionRepeat === 1 && (
                <div className="flex gap-5 items-center">
                  <LoadableButton
                    className="capitalize font-semibold text-primary rounded-md mt-8"
                    type="button"
                    lable="Repeat Section"
                    loadingLable="Loading section..."
                    onClick={handleRepeatSection}
                    isLoading={isLoadingRepeat}
                  />
                  {isPrimarySection === 0 ? (
                    <LoadableButton
                      className="capitalize font-semibold text-primary rounded-md mt-8"
                      type="button"
                      lable="Remove Section"
                      loadingLable="Removing section..."
                      onClick={handleRemoveSection}
                      isLoading={isLoadingRemoveSection}
                    />
                  ) : null}
                </div>
              )}

              <div className="question-container w-4/5">
                <div className="mt-8 mb-6">
                  <div className="flex items-center gap-4">
                    {isNext && (
                      <Button
                        size="large"
                        type="primary"
                        className="py-6 px-12 uppercase font-semibold"
                        onClick={handleNextStep}
                      >
                        NEXT
                        <img
                          src="/assets/icons/arrow-right.svg"
                          className="size-4"
                          alt="icon"
                        />
                      </Button>
                    )}
                    {(file?.is_submit === 0 || file?.unsubmit === 1) && (
                      <LoadableButton
                        className="uppercase font-semibold text-primary border border-primary rounded-md px-12 py-3"
                        type="button"
                        lable="Submit"
                        loadingLable="Submitting..."
                        onClick={handleSubmit}
                        isLoading={isLoadingSubmit}
                      />
                    )}
                    <div className="flex items-center gap-1">
                      <div className="size-[22px]">
                        {isCompleted ? (
                          <img
                            className="size-6"
                            src="/assets/icons/check-circle.svg"
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="size-[22px]"
                            src="/assets/icons/x-circle.svg"
                            alt="icon"
                          />
                        )}
                      </div>
                      <p className="text-secondaryLight1">
                        {`All answers are ${isCompleted ? "" : "not"} saved.`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionAnswerFlow;
