import React, { useEffect, useState } from "react";
import moment from "moment";
import column from "../../columns/payments/payments";
import { GetPaymentList } from "../../api/request/payments/payments";
import SkeletonTable from "../../components/table/SkeletonTable";

const Payments = () => {
  const [paymentList, setPaymentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPaymentList = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetPaymentList({ type: "client" });
      if (data.status) {
        setPaymentList(
          data?.data?.map((item) => {
            return {
              ...item,
              name: item?.file?.service_name_slug,
              created_at: item?.created_at ? moment(item?.created_at).format("lll") : "--",
            };
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPaymentList();
  }, []);

  return (
    <div>
      <div className="border rounded-xl my-5 bg-white">
        <div className="flex justify-between mx-5 mt-5">
          <p className=" font-semibold text-2xl">Payments</p>
        </div>
        <div className="my-6">
          <SkeletonTable columns={column} data={paymentList} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default Payments;
