import React from "react";
import { Input } from "antd";
import { SubmitAnswerApi } from "../../../api/request/service";
import toast from "react-hot-toast";

const MyNumber = ({ data }) => {
  const question = data?.service_question;
  const isReviewedChecked = data?.is_sealed;
  const disabledStyle = { cursor: "not-allowed", opacity: 0.6 };

  const onBlur = async (event) => {
    if (!event?.target?.value) return;
    try {
      const answer = { id: data?.id, response_text: event?.target?.value };
      await SubmitAnswerApi(answer);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      {data?.is_show === 1 && (
        <div className="mt-6">
          <div className="flex items-start gap-2 mb-2">
            {question?.is_required === 1 && (
              <p className="text-red-600 text-lg">*</p>
            )}
            <p>{question?.field_label}</p>
          </div>

          <Input
            className="py-3 ps-4"
            disabled={isReviewedChecked}
            style={isReviewedChecked ? disabledStyle : {}}
            size="large"
            type="number"
            placeholder="Number"
            defaultValue={data?.response_text}
            onBlur={onBlur}
          />
        </div>
      )}
    </div>
  );
};

export default MyNumber;
