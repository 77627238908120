import React from "react";
import { Select } from "antd";
import { SubmitAnswerApi } from "../../../api/request/service";
import toast from "react-hot-toast";

const MyDropdown = ({ data, reloadSections, reloadQuestions }) => {
  const question = data?.service_question;
  const isReviewedChecked = data?.is_sealed;
  const disabledStyle = { cursor: "not-allowed" };

  const onChange = async (value) => {
    if (!value) return;
    try {
      const answer = {
        id: data?.id,
        response_text: value,
      };
      await SubmitAnswerApi(answer);

      // Update section and question data so conditional section and question can render
      reloadSections(false);
      reloadQuestions(false);
    } catch (error) {
      if (error?.response?.data?.message) toast.error(error?.response?.data?.message);
    }
  };

  const createOptions = (options) => {
    return options?.map((item) => {
      return { value: item, label: item };
    });
  };

  return (
    <div>
      {data?.is_show === 1 && (
        <div className="mt-6">
          <div className="flex items-start gap-2 mb-2">
            {question?.is_required === 1 && <p className="text-red-600 text-lg">*</p>}
            <p>{question?.field_label}</p>
          </div>

          <Select
            className="w-full h-[49px]"
            disabled={isReviewedChecked}
            style={isReviewedChecked ? disabledStyle : {}}
            placeholder="Select option"
            options={createOptions(question?.options)}
            defaultValue={data?.response_text}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default MyDropdown;
