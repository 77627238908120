import React, { useState } from 'react';
import LoadableButton from '../../components/button/LoadableButton.jsx';
import { CreatePaymentSessionApi } from '../../api/request/payments/payments.js';
import { useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';

export default function Stripe({ orgData }) {

    const stripe = useStripe();
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsLoadingPayment(true);
            let params = {
                // line_items: [
                //     {
                //         name: "Taxtribe License",
                //         unit_amount: 1000,
                //         quantity: 1
                //     },
                //     {
                //         name: "Taxtribe Add-ons",
                //         unit_amount: 5000,
                //         quantity: 2
                //     },
                //     {
                //         name: "Taxtribe Support",
                //         unit_amount: 1500,
                //         quantity: 3
                //     }
                // ],
                // success_url: process.env.REACT_APP_STRIPE_SUCCESS_URL,

                file_id: orgData?.file_id,
                cancel_url: process.env.REACT_APP_STRIPE_CANCEL_URL
            };

            const { data } = await CreatePaymentSessionApi(params);

            if (data?.status) {
                // Redirect to Stripe Checkout
                const result = await stripe.redirectToCheckout({
                    sessionId: data?.data?.session_id,
                });
                if (result.error) {
                    console.error(result.error.message);
                }
            }

        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoadingPayment(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <LoadableButton
                className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500'
                type="submit"
                lable='Pay'
                isLoading={isLoadingPayment}
                loadingLable='Paying...'
            />
        </form>
    )
}