import React from "react";
import { Table } from "antd";

const SkeletonTable = ({ columns = [], data = [], isLoading }) => {
  return (
    <Table className="table-responsive" columns={columns} dataSource={data} loading={isLoading} sortDirections={true} pagination={true} />
  );
};

export default SkeletonTable;
