import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Spin } from "antd";
import MySegmented from "../../components/segmented/Segmented";
import RadialBarChart from "../../components/chart/RadialBarChar";
import Add from "../../assets/Add";
import { GetFileListApi } from "../../api/request/service";

const Files = () => {
  const navigate = useNavigate();
  const primaryColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--primary")
    ?.trim();
  const [segmentedValue, setSegmentedValue] = useState("Active Files");
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const getFilterStatus = (type) => {
    switch (type) {
      case "Active Files":
        return "status_active";
      case "Archive Files":
        return "is_archive";
      case "Unsubmitted":
        return "unsubmit";
      default:
        return "";
    }
  };

  const getFileList = async () => {
    try {
      setIsLoading(true);
      let params = { type: getFilterStatus(segmentedValue) };
      const { data } = await GetFileListApi(params);
      if (data?.status) {
        setFileList(data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setFileList([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFileList();
  }, [segmentedValue]);

  return (
    <div>
      <div className="border rounded-xl p-8 flex items-center justify-between bg-White">
        <div>
          <img
            src="/assets/images/start-file-banner.png"
            alt="image"
            className=" object-contain"
          />
        </div>
        <div>
          <p className="font-bold text-2xl">Your project document with us</p>
          <p className="text-secondaryLight1 mt-4">
            Add project data, create thematic pages, edit data, share
            information with team members
          </p>
          <button
            onClick={() => navigate("/create_files")}
            className="flex items-center justify-center gap-3 mt-4 bg-secondary text-white font-medium px-6 py-2 rounded-lg "
          >
            <Add />
            Start New File
          </button>
        </div>
      </div>

      <div className="mt-8">
        <MySegmented
          options={["Active Files", "Archive Files", "Unsubmitted"]}
          value={segmentedValue}
          setValue={setSegmentedValue}
        />

        <div>
          {isLoading ? (
            <div className="flex flex-col items-center gap-2 mt-16">
              <Spin />
              <p className="text-secondaryLight1">Loading...</p>
            </div>
          ) : (
            <div className="my-6">
              {fileList?.length ? (
                <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5">
                  {fileList?.map((item, index) => (
                    <div
                      key={index}
                      className="border rounded-2xl py-4 ps-4 flex items-center justify-between cursor-pointer bg-White"
                      onClick={() => navigate(`/file/${item?.id}`)}
                    >
                      <div>
                        <p className="font-semibold text-lg">
                          {item?.service_type?.service_name}
                        </p>
                        <p className="font-medium mt-3">
                          <span className="text-secondaryLight1">
                            Last Updated :{" "}
                          </span>
                          {moment(item?.updated_at).format("ll")}
                        </p>
                        {item?.is_submit ? (
                          <div className="flex items-center gap-2 mt-3">
                            <div className="size-3 rounded-full bg-primary"></div>
                            <p className="font-medium capitalize">
                              {item?.status}
                            </p>
                          </div>
                        ) : (
                          <button className="rounded-full bg-primary text-white text-sm font-semibold uppercase py-1 px-6 mt-3">
                            Pending
                          </button>
                        )}
                      </div>
                      <div>
                        <RadialBarChart
                          values={[item?.completion_percentage]}
                          labels={["Completed"]}
                          colors={[primaryColor]}
                          value={true}
                          height={200}
                          width={150}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-secondaryLight1 text-center mt-12">
                  No files found
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Files;
