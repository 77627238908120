import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Button, Spin } from "antd";
import LoadableButton from "../../../components/button/LoadableButton";
import {
  GetServiceTypeListApi,
  CreateFileApi,
} from "../../../api/request/service";

const CreateFiles = () => {
  const navigate = useNavigate();
  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  const getServiceList = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetServiceTypeListApi();
      if (data?.status) setService(data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getServiceList();
  }, []);

  const createFileHandler = async () => {
    try {
      setIsCreateLoading(true);
      let params = {
        service_type_id: selectedService?.id,
      };
      const { data } = await CreateFileApi(params);
      if (data?.status) {
        toast.success(data?.message);
        navigate(`/file/${data?.data?.id}`);
      }
      setIsCreateLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsCreateLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white border rounded-xl">
      <h2 className="mb-3 text-xl font-semibold">Available Services</h2>

      {isLoading ? (
        <div className="flex flex-col items-center gap-2 mt-16">
          <Spin />
          <p className="text-secondaryLight1">Loading...</p>
        </div>
      ) : (
        <div>
          <div className="w-full flex flex-wrap gap-5">
            {service?.map((item, index) => (
              <div
                key={`service_${index}`}
                className={`py-3 px-12 font-medium border-2 rounded-md cursor-pointer duration-500 ${
                  selectedService?.id === item?.id
                    ? "text-primary border-primary bg-primaryLight"
                    : "text-secondaryLight1"
                }`}
                onClick={() => setSelectedService(item)}
              >
                {item?.name}
              </div>
            ))}
          </div>

          <div className="mt-10 mb-6">
            <div className="flex items-center gap-4">
              <LoadableButton
                className="bg-primary text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500"
                isLoading={isCreateLoading}
                onClick={createFileHandler}
                lable="Create"
                loadingLable="Creating file..."
                disabled={!selectedService}
              />
              <Button
                size="large"
                type="primary"
                ghost
                className="py-4 px-8 uppercase font-semibold"
                onClick={() => navigate("/files")}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateFiles;
