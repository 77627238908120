import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { Input, Dropdown, Drawer, Breadcrumb, Form, Upload, Image } from "antd";
import SkeletonTable from "../../../components/table/SkeletonTable";
import {
  GetFolderListApi,
  GetChildFolderListApi,
  UploadFileFolderApi,
} from "../../../api/request/documents/documents.js";
import columns from "../../../columns/files/trackmydoc-upload.js";
import ModalComponent from "../../global/modals/ModalComponent.jsx";
import LoadableButton from "../../button/LoadableButton";
import { SubmitAnswerApi } from "../../../api/request/service.js";

const MyFile = ({ data }) => {
  const question = data?.service_question;
  const isReviewedChecked = data?.is_sealed;
  const [uploadFileForm] = Form.useForm();
  const [isDeviceUpload, setIsDeviceUpload] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isTrackmyDocOpen, setIsTrackmyDocOpen] = useState(false);
  const [isFolderListLoading, setIsFolderListLoading] = useState(false);
  const [folderListForTrackmyDoc, setFolderListForTrackmyDoc] = useState([]);
  const [trackmyDocBreadcrumb, setTrackmyDocBreadcrumb] = useState([]);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [uploadFileFormValue, setUploadFileFormValue] = useState({ files: [] });
  const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const disabledStyle = { cursor: "not-allowed", opacity: 0.6 };

  const UploadButton = (
    <button type="button" className="flex flex-col items-center gap-2">
      <div className="size-7">
        <img src="/assets/icons/upload.svg" alt="icon" />
      </div>
      Upload
    </button>
  );

  useEffect(() => {
    if (!isTrackmyDocOpen) setIsDeviceUpload(false);
  }, [isTrackmyDocOpen]);

  const handleTrackmyDoc = () => {
    getFolderList();
    setIsTrackmyDocOpen(true);
    setTrackmyDocBreadcrumb([]);
  };

  const handleDevice = () => {
    getFolderList();
    setIsDeviceUpload(true);
    setIsTrackmyDocOpen(true);
    setTrackmyDocBreadcrumb([]);
  };

  const items = [
    {
      key: "1",
      label: <div onClick={handleTrackmyDoc}>Upload from TrackmyDoc</div>,
    },
    {
      key: "2",
      label: <div onClick={handleDevice}>Upload from Device</div>,
    },
  ];

  const getBase64 = (file) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    uploadFileForm.setFieldsValue({ files: newFileList });
    if (newFileList?.length == 0) {
      uploadFileForm.setFieldsValue({ files: [] });
    }
  };

  const sendFile = async (file) => {
    if (!file) return;
    try {
      const answer = {
        id: data?.id,
        response_text: file?.original_name,
        document_id: file?.id,
      };
      await SubmitAnswerApi(answer);
      setUploadedFile(file);
      setIsTrackmyDocOpen(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
    }
  };

  const closeTrackmyDoc = () => {
    setIsTrackmyDocOpen(false);
    setFolderListForTrackmyDoc([]);
  };

  const getFolderList = async () => {
    try {
      setIsFolderListLoading(true);
      const { data } = await GetFolderListApi();
      if (data?.status) {
        setFolderListForTrackmyDoc(
          data?.data?.map((item) => {
            return {
              ...item,
              name: (
                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={() => openFolder(item)}
                >
                  <img
                    src="/assets/icons/folder-red.svg"
                    alt="icon"
                    className="size-5"
                  />
                  <p>{item?.original_name}</p>
                </div>
              ),
              addedDate: item?.created_at
                ? moment(item?.created_at).format("lll")
                : "--",
            };
          })
        );
      }
      setIsFolderListLoading(false);
    } catch (error) {
      setIsFolderListLoading(false);
    }
  };

  const getIcons = (file) => {
    const fileExtension = file.split(".").pop().toLowerCase() || null;

    switch (fileExtension) {
      case "pdf":
        return (
          <img
            src="/assets/icons/pdf-template.svg"
            alt="icon"
            className="size-7"
          />
        );
      case "jpeg":
      case "jpg":
      case "png":
        return (
          <img
            src="/assets/icons/img-template.svg"
            alt="icon"
            className="size-7"
          />
        );
      case "doc":
      case "docx":
        return (
          <img
            src="/assets/icons/doc-template.svg"
            alt="icon"
            className="size-7"
          />
        );
      case "csv":
        return (
          <img
            src="/assets/icons/csv-template.svg"
            alt="icon"
            className="size-7"
          />
        );
      case "xlsx":
        return (
          <img
            src="/assets/icons/excel-template.svg"
            alt="icon"
            className="w-6 h-7"
          />
        );
      default:
        return (
          <img
            src="/assets/icons/folder-red.svg"
            alt="icon"
            className="size-5"
          />
        );
    }
  };

  const openFolder = async (folder) => {
    try {
      setIsFolderListLoading(true);
      setCurrentFolder(folder);
      const { data } = await GetChildFolderListApi({ folder_id: folder?.id });
      setTrackmyDocBreadcrumb(
        data?.breadcrumb?.map((item) => {
          return {
            title: (
              <p
                className="cursor-pointer"
                onClick={() => openFolder({ id: item?.id })}
              >
                {item?.name}
              </p>
            ),
          };
        })
      );
      setFolderListForTrackmyDoc(
        data?.data?.map((item) => {
          return {
            ...item,
            name: (
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() =>
                  item?.type == "folder" ? openFolder(item) : sendFile(item)
                }
              >
                {getIcons(item?.original_name)}
                <p>{item?.original_name}</p>
              </div>
            ),
            addedDate: item?.created_at
              ? moment(item?.created_at).format("lll")
              : "--",
          };
        })
      );
      setIsFolderListLoading(false);
    } catch (error) {
      setIsFolderListLoading(false);
    }
  };

  const uploadFileHandler = async (values) => {
    const extractOriginFileObjs = (fileArray) => {
      return fileArray.map((file) => file.originFileObj);
    };
    try {
      if (!currentFolder) {
        toast.error("Please select folder first to upload.");
        return;
      }
      setIsUploadFileLoading(true);
      let params = {
        files: extractOriginFileObjs(values?.files),
        parent_id: currentFolder?.id,
      };
      let apiHeader = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await UploadFileFolderApi(params, apiHeader);
      if (data?.status) {
        toast.success(data?.message);
        openFolder(currentFolder);
        uploadFileForm.resetFields();
        setIsOpenUploadModal(false);
      }
      setIsUploadFileLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsUploadFileLoading(false);
    }
  };

  return (
    <div>
      {data?.is_show === 1 && (
        <div className="mt-6">
          <div className="flex items-start gap-2 mb-2">
            {question?.is_required === 1 && (
              <p className="text-red-600 text-lg">*</p>
            )}
            <p>{question?.field_label}</p>
          </div>
          <Dropdown menu={{ items }} trigger="click">
            <Input
              className="py-3 ps-4"
              disabled={isReviewedChecked}
              style={isReviewedChecked ? disabledStyle : {}}
              size="large"
              readOnly
              onClick={(e) => e.preventDefault()}
              value={
                uploadedFile?.original_name ||
                data?.response_text ||
                "Choose file"
              }
              prefix={
                <img
                  src="/assets/icons/upload-file.svg"
                  className="size-6"
                  alt="icon"
                />
              }
            />
          </Dropdown>
        </div>
      )}

      <Drawer
        title="Select file"
        open={isTrackmyDocOpen}
        onClose={closeTrackmyDoc}
        width={820}
        maskClosable={false}
      >
        <div className="relative h-full">
          <div className="border-2 rounded-lg pb-3">
            {trackmyDocBreadcrumb?.length > 0 && (
              <Breadcrumb
                className="my-3 mx-4"
                separator={
                  <div className="h-full w-full flex items-center">
                    <img
                      src="/assets/icons/right-angle.svg"
                      className="size-3"
                      alt="icon"
                    />
                  </div>
                }
                items={trackmyDocBreadcrumb}
              />
            )}
            <div
              className={`${
                trackmyDocBreadcrumb?.length > 0 ? "table-radius-0" : ""
              }`}
            >
              <SkeletonTable
                columns={columns}
                data={folderListForTrackmyDoc}
                isLoading={isFolderListLoading}
              />
            </div>
          </div>
          {isDeviceUpload && currentFolder && (
            <button
              size="large"
              onClick={() => setIsOpenUploadModal(true)}
              className="flex gap-3 px-4 py-2 rounded-lg uppercase font-semibold text-primary border border-primary absolute bottom-0 right-4"
            >
              <img
                src="/assets/icons/upload-file.svg"
                className="size-5"
                alt="icon"
              />
              Upload
            </button>
          )}
        </div>
      </Drawer>

      {/* --- UPload file modal --- */}
      <ModalComponent
        isOpen={isOpenUploadModal}
        setIsOpen={setIsOpenUploadModal}
        title="Upload a new file"
        width={600}
      >
        <Form
          className="w-full"
          autoComplete="off"
          form={uploadFileForm}
          initialValues={uploadFileFormValue}
          onFinish={uploadFileHandler}
        >
          <div>
            <Form.Item
              name="files"
              rules={[{ required: true, message: "Please upload file." }]}
            >
              <Upload
                beforeUpload={() => false}
                multiple
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : UploadButton}
              </Upload>
            </Form.Item>
            {previewImage && (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
          </div>
          <LoadableButton
            className="bg-primary text-sm text-white font-medium uppercase px-8 py-2 mt-4 rounded-lg themeHover duration-500"
            type="submit"
            lable="Upload"
            isLoading={isUploadFileLoading}
            loadingLable="Uploading file..."
          />
        </Form>
      </ModalComponent>
    </div>
  );
};

export default MyFile;
