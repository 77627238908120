import api from "../index";

export const GetServiceTypeListApi = () => api.post("servicetypelist");
export const GetFileListApi = (data) => api.post('clientfilelist', data);
export const CreateFileApi = (data) => api.post('createclientfile', data);
export const GetSectionListApi = (data) => api.post('clientsectionlist', data);
export const GetSectionWiseQuestionApi = (data) => api.post('clientfilequelist', data);
export const SubmitAnswerApi = (data) => api.post('addupdateanswer', data);
export const SubmitFileApi = (data) => api.post('filesubmit', data);
export const SetActiveTaskApi = (data) => api.post('run-task', data);
export const RepeatSectionApi = (data) => api.post('createclientsection', data);
export const RemoveSectionApi = (data) => api.post('deletefilesection', data);
