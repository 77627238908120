const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Name',
        dataIndex: 'files',
        render: (value,record) => record?.files?.service_name_slug ? record?.files?.service_name_slug : '--'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: defaultRender,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: defaultRender,
    },
    {
        title: 'Action',
        dataIndex: 'action',
        render: defaultRender,
    },
];

export default columns;