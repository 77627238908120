import React from "react";
import MyInput from "./formElements/Input.jsx";
import MyDatePicker from "./formElements/DatePicker.jsx";
import MyRadio from "./formElements/Radio.jsx";
import MyCheckBox from "./formElements/CheckBox.jsx";
import MyEmail from "./formElements/Email.jsx";
import MyNumber from "./formElements/Number.jsx";
import MyDropdown from "./formElements/Dropdown.jsx";
import YesNo from "./formElements/YesNo.jsx";
import MyFile from "./formElements/File.jsx";

const QuestionContainer = ({ questions, reloadSections, reloadQuestions }) => {
  const QuestionRender = (question) => {
    switch (question?.service_question?.field_type) {
      case "input":
        return <MyInput data={question} />;
      case "email":
        return <MyEmail data={question} />;
      case "number":
        return <MyNumber data={question} />;
      case "date":
        return <MyDatePicker data={question} />;
      case "radio":
        return (
          <MyRadio
            data={question}
            reloadSections={reloadSections}
            reloadQuestions={reloadQuestions}
          />
        );
      case "checkbox":
        return (
          <MyCheckBox
            data={question}
            reloadSections={reloadSections}
            reloadQuestions={reloadQuestions}
          />
        );
      case "dropdown":
        return (
          <MyDropdown
            data={question}
            reloadSections={reloadSections}
            reloadQuestions={reloadQuestions}
          />
        );
      case "yes-no":
        return (
          <YesNo
            data={question}
            reloadSections={reloadSections}
            reloadQuestions={reloadQuestions}
          />
        );
      case "file":
        return <MyFile data={question} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {questions?.map((question) => (
        <div>
          {question?.is_label === 1 ? (
            <div className="mb-3 mt-6 text-base font-semibold">
              {question?.field_label}
            </div>
          ) : (
            <div>
              {QuestionRender(question)}
              {question?.question?.is_show === 1 ? (
                <p className="mt-1 text-secondaryLight1">
                  {question?.question?.description || ""}
                </p>
              ) : null}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuestionContainer;
